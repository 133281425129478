import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import UserMenu from '../../components/Menu/UserMenu'

const DIV1 = styled.div`
  display: flex;
  background-color: white;
  padding: 20px 0 20px 0;
`

const DIV = styled.div`
  padding: 10px;
`

const SpanLeft = styled.span`
  position: relative;
  float: left;
  padding: 10px;
`

const SpanRight = styled.span`
  position: relative;
  float: right;
  padding: 10px;
`

const Title = styled.p`
  font-size: 25px;
  padding: 8px;

  @media (max-width: 900px) {
    padding: 5px;
  }
`

const Nav = styled.nav`
  padding: 8px;
  @media (max-width: 900px) {
    padding: 5px;
  }
`

const SpanBlue = styled.span`
  color:blue
`

const SpanRed = styled.span`
  color:red
`

const SpanOrange = styled.span`
  color:orange
`

const SpanGreen = styled.span`
  color:green
`

const Header = () => {
  return (
    <>
      <DIV>
        <SpanLeft>
          <Title>
            <SpanBlue>A</SpanBlue>
            <SpanRed>S</SpanRed>
            <SpanOrange>K</SpanOrange>
            <SpanBlue>S</SpanBlue>
            <SpanRed>I</SpanRed>
            <SpanGreen>F</SpanGreen>
            <SpanBlue>U</SpanBlue>
          </Title>
        </SpanLeft>
        <SpanRight>
          <UserMenu />
        </SpanRight>
      </DIV>
      <DIV1>
        <div>
          <Link href="/main">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Create contract</a>
          </Link>
          &nbsp;|&nbsp;
        </div>
        <div>
          <Link href="/send">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Send token and confirm</a>
          </Link>
          &nbsp;|&nbsp;
        </div>
        <div>
          <Link href="/claim">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Claim token</a>
          </Link>
        </div>
      </DIV1>
    </>
  )
}

export default Header
