import { ResetCSS } from '@asksifu/uikit'
import { ToastListener } from 'contexts/ToastsContext'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { Fragment } from 'react'
import { NextPage } from 'next'
import ErrorBoundary from 'components/ErrorBoundary'
import Providers from 'Providers'
import GlobalStyle from 'style/Global'
import { useStore } from 'state'
import Header from '../views/Header'

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="Asksifu" />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:description" content="Asksifu" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Asksifu" />
        <title>Asksifu</title>
      </Head>
      <Providers store={store}>
        <ResetCSS />
        <GlobalStyle />
        <App {...props} />
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  return (
    <ProductionErrorBoundary>
      <Layout>
        <Header />
        <Component {...pageProps} />
      </Layout>
      <ToastListener />
    </ProductionErrorBoundary>
  )
}

export default MyApp
